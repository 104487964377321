<template>
  <div class="row">
    <div class="col-md-12 card p-0">
      <div class="card-body row p-0">
        <div class="col-sm-12">
          <my-filter :rules="rules" v-on:filter-change="updateFilter"></my-filter>
        </div>
        <div class="col-sm-12">
          <my-table :columnDefs="columnDefs" v-bind:data-rows="orders" :actions="actions" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { MessageBox, Message, Dialog, Button } from 'element-ui'
  import MyTable from 'src/components/UIComponents/Table.vue'
  import MyFilter from 'src/components/UIComponents/Filter.vue'
  import schemas from './order-schemas'
  import dtHelper from 'src/helpers/datatable'

  export default{
    components: {
      MyTable,
      MyFilter,
      ElDialog: Dialog,
      ElButton: Button
    },
    computed: {
      orders() {
        let rows = this.$store.state.enterpriseOrders;
        return dtHelper.filterByRules(rows, this.filterOutput);
      },
    },
    data () {
      return {
        filterOutput: [],
        columnDefs: dtHelper.buildColumDefs(schemas),
        filter: {},
        rules: dtHelper.buildRules(schemas),
        actions: [
          {
            type: 'primary',
            icon: 'nc-icon nc-ruler-pencil',
            callback: this.edit
          }
        ]
      }
    },
    mounted() {
      window.AP = this;
      this.$store.dispatch('fetchOrderEnterprises');
      this.$store.dispatch('setPageTitle', 'Danh sách đơn hàng');
      this.$store.dispatch('setCurrentActions', []);
    },
    methods: {
      updateFilter(filterOutput) {
        this.filterOutput = filterOutput;
      },

      edit(index, row) {
        this.$router.push('/enterprise/orders/' + row.id);
      }

    },
    destroyed(){
      this.$store.dispatch('setCurrentActions', []);
    }
  }
</script>
