export default [
  {
    prop: 'id',
    label: 'ID',
    minWidth: 100,
    type: 'id'
  },
  {
    prop: 'name',
    label: 'User',
    minWidth: 180,
    type: 'text'
  },
  {
    prop: 'package_name',
    label: 'Gói',
    minWidth: 180,
    type: 'text'
  },
  {
    prop: 'status_format',
    label: 'Thanh toán',
    minWidth: 180,
    type: 'select',
    options: [{
        value: 'Chưa thanh toán',
        title: 'Chưa thanh toán'
      },
      {
        value: 'Đã thanh toán',
        title: 'Đã thanh toán'
      }
    ]
  },
  {
    prop: 'email',
    label: 'Email',
    minWidth: 220,
    type: 'text'
  },
  {
    prop: 'paygate_name',
    label: 'Cổng thanh toán',
    minWidth: 200,
    type: 'select',
    options: [{
      value: 1,
      title: 'OnePay'
    },
    {
      value: 2,
      title: 'ZaloPay'
    },
    {
      value: 3,
      title: 'MoMo'
    },
    {
      value: 4,
      title: 'Chuyển khoản'
    },
    {
      value: 0,
      title: 'PayPal'
    }]
  },
  {
    prop: 'created_at',
    label: 'Ngày tạo',
    minWidth: 120,
    type: 'datetime'
  }
]
